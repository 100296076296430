import SockJS from 'sockjs-client';
import webstomp, { Client, Message } from 'webstomp-client';

interface Configuration {
  readonly wsUrl: string;
  readonly debugMode: boolean;
  readonly accessToken: () => string;
}

export class StompWsClient {
  private readonly wsUrl: string;
  private readonly debugMode: boolean;
  private readonly accessToken: () => string;

  private client?: Client;

  constructor(params: Configuration) {
    this.wsUrl = params.wsUrl;
    this.debugMode = params.debugMode;
    this.accessToken = params.accessToken;
  }

  private initWsStompClient(): Client {
    return webstomp.over(new SockJS(this.wsUrl), {
      debug: this.debugMode,
      protocols: webstomp.VERSIONS.supportedProtocols(),
    });
  }

  isConnected(): boolean {
    return this.client !== undefined && this.client.connected;
  }

  connect(): Promise<void> {
    this.client = this.initWsStompClient();

    return new Promise<void>((resolve, reject) => {
      this.client?.connect(this.createHeaders(), () => resolve(), err => reject(err));
    });
  }

  disconnect(): void {
    this.client?.disconnect();
  }

  subscribe<T>(destination: string, onMessage: (message: T) => void): string {
    if (!this.isConnected()) {
      console.error('[STOMP WS CLIENT] Unable to subscribe due to disconnected websocket');
      throw new Error('Unable to subscribe due to disconnected websocket');
    }

    const subscription = this.client!.subscribe(destination, (message: Message) => {
      const wsMessage = JSON.parse(message.body);
      onMessage(wsMessage);
    }, this.createHeaders());

    return subscription.id;
  }

  unsubscribe(subscriptionId: string): void {
    this.client?.unsubscribe(subscriptionId, this.createHeaders());
  }

  send(destination: string, message: {}): void {
    if (!this.isConnected()) {
      console.error('[STOMP WS CLIENT] Unable to send message due to disconnected websocket');
      throw new Error('Unable to send message due to disconnected websocket');
    }

    this.client?.send(destination, JSON.stringify(message), this.createHeaders());
  }

  private createHeaders(): {} {
    return { 'X-Authorization': `Bearer ${this.accessToken()}` };
  }
}