import { Timestamp } from '../datetime';

export enum MetaMessageStatus {
  REJECTED = 'REJECTED',
  PREPARED = 'PREPARED',
  NEW = 'NEW',
  SENT = 'SENT',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  FAILED = 'FAILED',
}

export enum MetaMessageType {
  DOCUMENT = 'document',
  TEXT = 'text',
  TEMPLATE_WHATSAPP = 'template[whatsapp]',
  TEMPLATE_MESSENGER = 'template[messenger]',
  IMAGE = 'image',
  LOCATION = 'location',
  VIDEO = 'video',
  BUTTON = 'button',
  INTERACTIVE = 'interactive',
  REACTION = 'reaction',
}

export interface MetaMessage {
  readonly id: string;
  readonly createdAt: Timestamp;
  readonly updatedAt: Timestamp;
  readonly direction: 'outgoing' | 'incoming';
  readonly type: MetaMessageType;
  readonly status: MetaMessageStatus;
  readonly text: string;
  readonly mediaURL?: string;
  readonly fileName?: string;
  readonly statusDetails?: string;
}

export interface MetaConversationId {
  readonly accountId: string;
  readonly targetId: string;
}
