import { HttpClient } from '../common/httpClient';
import { MetaAccount, MetaChannel } from '../../types/meta/metaAccount';
import { PageResponse } from '../common/pagingModel';
import dateTimeService from '../../service/dateTimeService';

type MetaAccountResponse = {
  readonly id: string;
  readonly businessAccountId: string;
  readonly source: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly isDisabled: boolean;
  readonly channel: string;
  readonly name: string;
  readonly appId: string;
  readonly metaAppId: string;
  readonly accessToken: string;
  readonly webhookVerifyToken: string;
  readonly tenantId: string;
};

export class MetaAccountApi {
  constructor(private readonly httpClient: HttpClient) { }

  private mapToMetaAccount(response: MetaAccountResponse): MetaAccount {
    return {
      id: response.id,
      appId: response.appId,
      channel: response.channel as MetaChannel,
      name: response.name,
      businessAccountId: response.businessAccountId,
      isDisabled: response.isDisabled,
      source: response.source,
      metaAppId: response.metaAppId,
      tenantId: response.tenantId,
      accessToken: response.accessToken,
      webhookVerifyToken: response.webhookVerifyToken,
      createdAt: dateTimeService.convertIsoDateToTimestamp(response.createdAt),
      updatedAt: dateTimeService.convertIsoDateToTimestamp(response.updatedAt),
    };
  }

  getAll(): Promise<MetaAccount[]> {
    return this.httpClient.get<PageResponse<MetaAccountResponse>>(`accounts`)
      .then(data => (
        data.content.map(it => this.mapToMetaAccount(it))
      ));
  }

  getAllByTenantId(tenantId: string): Promise<MetaAccount[]> {
    return this.httpClient.get<PageResponse<MetaAccountResponse>>(`tenants/${tenantId}/accounts`)
      .then(data => (
        data.content.map(it => this.mapToMetaAccount(it))
      ));
  }

  getWhatsappByTenantId(tenantId: string): Promise<MetaAccount[]> {
    return this.getAllByTenantId(tenantId).then(metaAccounts => (
      metaAccounts.filter(it => it.channel === MetaChannel.WHATSAPP)
    ));
  }

  async add(values: MetaAccount): Promise<void> {
    await this.httpClient.post<MetaAccount>(`accounts`, values);
  }

  getById(id: string): Promise<MetaAccount> {
    return this.httpClient.get<MetaAccountResponse>(`accounts/${id}`)
      .then(data => this.mapToMetaAccount(data));
  }

  async update(id: string, item: MetaAccount): Promise<void> {
    await this.httpClient.put<MetaAccount>(`accounts/${id}`, item);
  }

  async enable(id: string): Promise<void> {
    await this.httpClient.put<MetaAccount>(`accounts/${id}/enable`, {});
  }

  async disable(id: string): Promise<void> {
    await this.httpClient.put<MetaAccount>(`accounts/${id}/disable`, {});
  }

  async delete(id: string): Promise<void> {
    await this.httpClient.delete<MetaAccount>(`accounts/${id}`);
  }
}
