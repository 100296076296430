import { HttpClient } from '../common/httpClient';
import { MetaTarget } from '../../types/meta/metaTarget';
import phoneNumberService from '../../service/phoneNumberService';
import dateTimeService from '../../service/dateTimeService';

type MetaTargetResponse = {
  readonly id: string;
  readonly destination: string;
  readonly lastReceivedMessageAt?: string;
  readonly customerServiceWindowEnd?: string;
};

export class MetaTargetApi {
  constructor(private readonly httpClient: HttpClient) {}

  getAllByAccountId(accountId: string): Promise<MetaTarget[]> {
    return this.httpClient.get<MetaTargetResponse[]>(`/accounts/${accountId}/targets/all`)
      .then(data => (
        data.filter(it => phoneNumberService.isValidPhoneNumber(it.destination))
          .map(it => this.mapToMetaTarget(it))
      ));
  }

  private mapToMetaTarget(response: MetaTargetResponse): MetaTarget {
    return {
      id: response.id,
      phoneNumber: phoneNumberService.toPhoneNumberWithPlusSign(response.destination),
      ...(response.lastReceivedMessageAt && {
        lastReceivedMessageAt: dateTimeService.convertIsoDateToTimestamp(response.lastReceivedMessageAt),
      }),
    };
  }
}
