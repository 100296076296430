import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const HelpUserChannels = () => {
  return (
    <div style={{ padding: 5, marginBottom: 15 }}>
      <Typography style={{ marginBottom: 15 }} variant="body2" component="div">
        <FormattedMessage id="help.user.channels.1" />
      </Typography>
      <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.user.channels.2" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.user.channels.2.1" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.2.2" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.2.3" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.2.4" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.2.5" />
          <br />
          <br />
          <FormattedMessage id="help.user.channels.2.6" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.2.7" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.2.8" />
        </Typography>
      </div>

      <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.user.channels.3" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.user.channels.3.1" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.3.2" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.3.3" />
          <br />
          <br />
          <FormattedMessage id="help.user.channels.3.4" />
          <br />
          <br />
          <FormattedMessage id="help.user.channels.3.5" />
          <br />
          <br />
          <FormattedMessage id="help.user.channels.3.6" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.3.7" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.3.8" />
        </Typography>
      </div>
      <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.user.channels.4" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.user.channels.4.1" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.4.2" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.4.3" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.4.4" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.4.5" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.4.6" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.4.7" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.4.8" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.4.9" />
          <br />
          <br />
        </Typography>
      </div>
      {/* <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.user.channels.5" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.user.channels.5.1" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.5.2" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.5.3" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.5.4" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.5.5" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.5.6" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.5.7" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.user.channels.5.8" />
          </b>
          <br />
          <FormattedMessage id="help.user.channels.5.9" />
          <br />
          <br />
        </Typography>
      </div> */}
    </div>
  );
};

export default HelpUserChannels;
