import chatProxyWsConnection from './chatProxyWsConnection';
import backendChatWsConnection from './backendChatWsConnection';
import chatChannelProvider from '../chatChannelProvider';
import { ChatListener, ChatListenerCallbacks } from '../interface';

type CustomerInfo = {
  readonly customerId: number;
  readonly customerUuid: string;
};

class ChatWebsocketManager {
  private readonly chatListeners: ChatListener[];

  constructor() {
    this.chatListeners = chatChannelProvider.all()
      .map(chatChannel => chatChannel.listener);
  }

  async openConnection(customerInfo: CustomerInfo): Promise<void> {
    console.log('Opening chat websocket connections...');
    const { customerId, customerUuid } = customerInfo;

    await Promise.all([
      backendChatWsConnection.open(customerId),
      chatProxyWsConnection.open(customerUuid),
    ]);

    console.log('Chat websocket connections have been opened');
  }

  closeConnection(): void {
    console.log('Closing chat websocket connections...');

    chatProxyWsConnection.close();
    backendChatWsConnection.close();

    console.log('Chat websocket connections have been closed');
  }

  registerListeners(callbacks: ChatListenerCallbacks): void {
    this.chatListeners.forEach(listener => listener.register(callbacks));

    console.log('Chat listeners are registered');
  }

  unregisterListeners(): void {
    this.chatListeners.forEach(listener => listener.unregister());

    console.log('Chat listeners are unregistered');
  }
}

export default new ChatWebsocketManager();
