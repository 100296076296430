import { v4 as uuidv4 } from 'uuid';
import chatProxyWsConnection from '../websocket/chatProxyWsConnection';
import whatsappChatConfig from './whatsappChatConfig';
import phoneNumberService from '../../service/phoneNumberService';
import { ChatPublisher, SendChatMessageParams } from '../interface';
import { ChatProxyOutboundWsMessage, ChatProxyOutgoingMessage } from '../websocket/chatProxyWsMessage';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatAttachment, ChatMessage, ChatMessagePayloadType, ChatMessageStatus } from '../../types/chatMessage';
import dateTimeService from '../../service/dateTimeService';

export default class WhatsappChatPublisher implements ChatPublisher {
  sendChatMessage(params: SendChatMessageParams): Promise<ChatMessage> {
    const message = this.createOutgoingMessage(params);

    return new Promise((resolve, reject) => {
      try {
        chatProxyWsConnection.sendMessage(message);

        resolve(this.convertToChatMessage(message, params));
      } catch (err) {
        reject(err);
      }
    });
  }

  private createOutgoingMessage(params: SendChatMessageParams): ChatProxyOutboundWsMessage {
    return {
      channelType: SupportedChatChannel.WHATSAPP,
      accountId: params.senderId,
      targetId: params.clientId,
      destination: phoneNumberService.stripPlusSignFromPhoneNumber(params.clientPhoneNumber),
      payloadType: params.payloadType,
      messageId: uuidv4(),
      message: params.text,
      ...(params.attachment && {
        attachmentLink: params.attachment.link,
        fileName: params.attachment.filename,
      }),
    };
  }

  private convertToChatMessage(message: ChatProxyOutgoingMessage, params: SendChatMessageParams): ChatMessage {
    const attachments: ChatAttachment[] = [];
    if (params.attachment) {
      attachments.push({
        link: params.attachment.link,
        filename: params.attachment.filename,
      });
    }

    return {
      channel: SupportedChatChannel.WHATSAPP,
      senderId: params.senderId,
      clientId: params.clientId,
      msgId: message.messageId,
      date: dateTimeService.formatCurrentTimestamp(whatsappChatConfig.dateFormat),
      message: params.text,
      outgoing: true,
      payloadType: params.payloadType as ChatMessagePayloadType,
      attachments,
      status: ChatMessageStatus.SENDING,
      statusCode: 200,
      error: null,
    };
  }
}
