import { v4 as uuidv4 } from 'uuid';
import { appConfig } from '../../config';
import { ChatWsMessageType } from './chatWsWrapper';
import { SupportedChatChannel } from '../supportedChatChannel';
import { StompWsClient } from './stompWsClient';
import { ChatProxyInboundWsMessage, ChatProxyOutboundWsMessage } from './chatProxyWsMessage';

type Listener = {
  readonly channel: SupportedChatChannel,
  readonly msgType: ChatWsMessageType,
  readonly onWsMessage: (wsMessage: ChatProxyInboundWsMessage) => void,
};

type ListenersMap = {
  [listenerId: string]: Listener;
};

type ConnectionDetails = {
  readonly customerUuid: string;
  readonly subscriptionId: string;
};

class ChatProxyWsConnection {
  private connectionDetails?: ConnectionDetails;
  private listenersMap: ListenersMap = {};

  constructor(private readonly wsClient: StompWsClient) {}

  isOpen(): boolean {
    return this.connectionDetails !== undefined && this.wsClient.isConnected();
  }

  async open(customerUuid: string): Promise<void> {
    if (this.isOpen()) {
      throw new Error('Chat proxy websocket connection is already open!');
    }

    await this.wsClient.connect();

    const subscriptionId = this.wsClient.subscribe<ChatProxyInboundWsMessage>(`/queue/chat/${customerUuid}`,
      wsMessage => this.onWsMessage(wsMessage));

    this.connectionDetails = {
      customerUuid,
      subscriptionId,
    };
  }

  close(): void {
    if (this.isOpen()) {
      this.wsClient.unsubscribe(this.connectionDetails!.subscriptionId);
      this.wsClient.disconnect();

      this.connectionDetails = undefined;
    }
  }

  addListener(listener: Listener): string {
    const { channel, msgType } = listener;
    const listenerId = `${channel}-${msgType}-${uuidv4()}`;

    this.listenersMap[listenerId] = listener;

    return listenerId;
  }

  removeListener(listenerId: string): void {
    delete this.listenersMap[listenerId];
  }

  sendMessage(message: ChatProxyOutboundWsMessage): void {
    this.wsClient.send(`/app/customer/${this.connectionDetails?.customerUuid}`, message);
  }

  private onWsMessage(chatWsMessage: ChatProxyInboundWsMessage): void {
    const { channel, type } = chatWsMessage;

    Object.values(this.listenersMap)
      .filter(listener => listener.channel === channel && listener.msgType === type)
      .forEach(listener => listener.onWsMessage(chatWsMessage));
  }
}

export default new ChatProxyWsConnection(
  new StompWsClient({
    wsUrl: appConfig.CHAT_PROXY_URL_WS!,
    debugMode: Boolean(appConfig.DEBUG_WS === 'true'),
    accessToken: () => localStorage.getItem('token')!,
  })
);
