import { v4 as uuidv4 } from 'uuid';
import { appConfig } from '../../config';
import { ChatWsMessageType, ChatWsWrapper } from './chatWsWrapper';
import { SupportedChatChannel } from '../supportedChatChannel';
import { StompWsClient } from './stompWsClient';

type Listener = {
  readonly channel: SupportedChatChannel,
  readonly msgType: ChatWsMessageType,
  readonly onWsMessage: (wsMessage: ChatWsWrapper) => void,
};

type ListenersMap = {
  [listenerId: string]: Listener;
};

type ConnectionDetails = {
  readonly customerId: number;
  readonly subscriptionId: string;
};

class BackendChatWsConnection {
  private connectionDetails?: ConnectionDetails;
  private listenersMap: ListenersMap = {};

  constructor(private readonly wsClient: StompWsClient) {}

  isOpen(): boolean {
    return this.connectionDetails !== undefined && this.wsClient.isConnected();
  }

  async open(customerId: number): Promise<void> {
    if (this.isOpen()) {
      throw new Error('Backend chat websocket connection is already open!');
    }

    await this.wsClient.connect();

    const subscriptionId = this.wsClient.subscribe<ChatWsWrapper>(`/topics/chat/${customerId}`,
        wsMessage => this.onWsMessage(wsMessage));

    this.connectionDetails = {
      customerId,
      subscriptionId,
    };
  }

  close(): void {
    if (this.isOpen()) {
      this.wsClient.unsubscribe(this.connectionDetails!.subscriptionId);
      this.wsClient.disconnect();

      this.connectionDetails = undefined;
    }
  }

  addListener(listener: Listener): string {
    const { channel, msgType } = listener;
    const listenerId = `${channel}-${msgType}-${uuidv4()}`;

    this.listenersMap[listenerId] = listener;

    return listenerId;
  }

  removeListener(listenerId: string): void {
    delete this.listenersMap[listenerId];
  }

  private onWsMessage(chatWsMessage: ChatWsWrapper): void {
    const { channel, type } = chatWsMessage;

    Object.values(this.listenersMap)
      .filter(listener => listener.channel === channel && listener.msgType === type)
      .forEach(listener => listener.onWsMessage(chatWsMessage));
  }
}

export default new BackendChatWsConnection(
  new StompWsClient({
    wsUrl: appConfig.URL_WS!,
    debugMode: Boolean(appConfig.DEBUG_WS === 'true'),
    accessToken: () => localStorage.getItem('token')!,
  })
);
