import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  getEmail2sms,
  changeEmail2smsPageSize,
  setSort,
  setFilter,
  setSearch,
  resetResource,
  setPage,
} from '../../../actions/resources';
import DataTable from '../../DataTable';
import columns from './accessLogTableColumns';
import { change } from 'redux-form';
import { Theme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { showSnackBarMsg } from '../../../actions/snackbar';
import { AppState } from '../../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(4),
    flexGrow: 1,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const defaultSort = {
  by: 'createDateTime',
  dir: 'desc',
};

type Props = {
  readonly data: $TSFixMe[];
  readonly page: $TSFixMe;
  readonly role: $TSFixMe;
  readonly uuid: string;
  readonly links: $TSFixMe;
  readonly loading: boolean;
  readonly loadingLog?: boolean;
  readonly sort: { by: string, dir: string };
  readonly unauthorizedFilterOptions: $TSFixMe[];
  readonly reset: (resource: string) => void;
  readonly setPage: (resource: string, page: $TSFixMe) => void;
  readonly showSnackBarMsg: (msg: $TSFixMe, variant: string) => void;
  readonly filter: (resourceArrayName: string, filter: string) => void;
  readonly setPageSize: (resource: string, size: number, options: object) => void;
  readonly getData: (resource: string, options: object, reset?: boolean) => void;
}

const AccessLog = (props: Props) => {

  useEffect(() => {
    const { reset } = props;
    getUnauthorizedEmails();
    return () => {
      reset('unauthorizedMailModelList');
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const getUnauthorizedEmails = () => {
    const { getData, role, uuid } = props;
    getData(
      (role.SUPER_ADMIN) ? `/unauthorized_mail` : `/unauthorized_mail/${uuid}`, {
        defaultSort,
        resourceArrayName: 'unauthorizedMailModelList',
      }, true
    );
  };

  const onChangeRowsPerPage = (event: $TSFixMe) => {
    const { setPageSize, role, uuid } = props;
    setPageSize(
      (role.SUPER_ADMIN) ? `/unauthorized_mail` : `/unauthorized_mail/${uuid}`,
      event.target.value, {
      resourceArrayName: 'unauthorizedMailModelList',
    });
  };

  const onFilter = (filterString: string) => {
    const { filter, getData, role, uuid, page: pageObject, setPage } = props;
    filter('unauthorizedMailModelList', filterString);
    setPage('unauthorizedMailModelList', { ...pageObject, number: 0 });
    getData(
      (role.SUPER_ADMIN) ? `/unauthorized_mail` : `/unauthorized_mail/${uuid}`, {
      defaultSort,
      resourceArrayName: 'unauthorizedMailModelList',
    });
  };

  const handleChangePage = (event: React.SyntheticEvent, page: $TSFixMe) => {
    const { page: pageObject, setPage, getData, uuid, sort } = props;
    setPage('unauthorizedMailModelList', { ...pageObject, number: page });
    getData(
      (role.SUPER_ADMIN) ? `/unauthorized_mail` : `/unauthorized_mail/${uuid}`, {
      defaultSort: sort,
      resourceArrayName: 'unauthorizedMailModelList',
    });
  };

  const handleSortChange = (property: $TSFixMe) => {
    const { sort, getData, role, uuid } = props;
    if (sort.by === property) {
      sort.dir = sort.dir === 'asc' ? 'desc' : 'asc';
    } else {
      sort.by = property;
    }
    getData(
      (role.SUPER_ADMIN) ? `/unauthorized_mail` : `/unauthorized_mail/${uuid}`, {
      defaultSort: sort,
      resourceArrayName: 'unauthorizedMailModelList',
    });
  };

  const extraButtons = () => {
    return [
      {
        button: (
          <div>
            <Tooltip
              title={
                <FormattedMessage id="common.button.refresh" />
              }
            >
              <div>
                <Button
                  onClick={() => getUnauthorizedEmails()}
                  size="small"
                  disabled={loadingLog}
                  color="primary"
                >
                  <FormattedMessage id="common.button.refresh" />
                </Button>
              </div>
            </Tooltip>
            {loadingLog && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        ),
      },
    ];
  };

  const {
    page,
    data,
    role,
    loading,
    loadingLog,
    sort: injectedSort,
    unauthorizedFilterOptions,
    showSnackBarMsg,
    } = props;
  
  const sort = injectedSort || defaultSort;
  const options = {
    onFilter: onFilter,
    onChangeRowsPerPage: onChangeRowsPerPage,
    onHandleChangePage: handleChangePage,
    onHandleSortChange: handleSortChange,
    sort,
    extraButtons,
    elevation: 0,
  };
  const accessLogColumnsKey = 'accessLog';

  return (
    <React.Fragment>
      <DataTable
        columns={columns(role, unauthorizedFilterOptions, showSnackBarMsg)}
        data={data}
        page={page}
        title={<FormattedMessage id="administration.email2sms.accessLog.table.title" />}
        options={options}
        loading={loading}
        columnsKey={accessLogColumnsKey}
      />
    </React.Fragment>
  );
};

function mapStateToProps(state: AppState) {
  return {
    data: state.resources.unauthorizedMailModelList.data,
    uuid:state.login.userData.customerUuid || '',
    page: state.resources.unauthorizedMailModelList.page,
    links: state.resources.unauthorizedMailModelList.links,
    loading: state.resources.unauthorizedMailModelList.loading,
    sort: state.resources.unauthorizedMailModelList.sort,
    view: state.login.userData.authorizations.ADMIN_VIEWER,
    role: state.login.userData.authorizations,
    unauthorizedFilterOptions: state.app.unauthorizedFilterOptions,
  };
}

export default connect(mapStateToProps, {
  getData: getEmail2sms,
  setPageSize: changeEmail2smsPageSize,
  setSort,
  filter: setFilter,
  search: setSearch,
  reset: resetResource,
  change,
  showSnackBarMsg,
  setPage,
})(AccessLog);
