import React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LoginFooter from './LoginFooter';
import stylesConfig from '../../../config';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material';

const bgImage = stylesConfig.BACKGROUND_URL;
const background = require(`../../../styles/${bgImage}`);

const getBackgroundImage = () => {
  switch (stylesConfig.THEME) {
    case 'sunrise':
      return `linear-gradient(
        rgba(255,255,255,.2), 
        rgba(255,255,255,.2)), url(${background}) no-repeat center center fixed`;
    case 'telxira':
      return `linear-gradient(
        rgba(255,255,255,.2), 
        rgba(255,255,255,.2)), url(${background}) no-repeat bottom center fixed`;
    case 'gms':
      return `linear-gradient(
        rgba(255,255,255,.2), 
        rgba(255,255,255,.2)), url(${background}) no-repeat center`;
    default:
      return `linear-gradient(
      rgba(255,255,255,.2), 
      rgba(255,255,255,.2)), url(${background}) no-repeat bottom center fixed`;
  }
};

const getBackgroundSize = () => {
  switch (stylesConfig.THEME) {
    case 'gms':
      return 'contain';

    default:
      return 'cover';
  }
};

const getBackgroundColor = () => {
  switch (stylesConfig.THEME) {
    case 'gms':
      return 'rgba(2,193,114,1)';

    default:
      return 'rgba(0,0,0,0.1)';
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  loginFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: getBackgroundImage(),
    backgroundColor: getBackgroundColor(),
    webkitBackgroundSize: getBackgroundSize(),
    backgroundSize: getBackgroundSize(),
  },
  loginMainContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  readonly content: object;
  readonly subheader?: $TSFixMe;
  readonly title: string | object;
};

const BaseLoginView = ({ title, content, subheader }: Props) => {
  const classes = useStyles();

  return (
    <div style={{ height: '100vh' }}>
      <div className={classes.loginFlexContainer}>
        <div className={classes.loginMainContent}>
          <Card style={{ width: 320 }} elevation={12}>
            <CardHeader
              className={classes.cardHeader}
              avatar={
                <Avatar>
                  <AccountCircle />
                </Avatar>
              }
              title={title}
              titleTypographyProps={{
                style: { color: grey[50] },
                variant: 'subtitle1',
              }}
              subheaderTypographyProps={{
                style: { color: grey[50] },
              }}
              subheader={subheader}
            />
            <CardContent>{content}</CardContent>
          </Card>
        </div>
        <LoginFooter />
      </div>
    </div>
  );
};

export default BaseLoginView;
