import chatProxyWsConnection from './chatProxyWsConnection';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatConfig, ChatListener, ChatListenerCallbacks } from '../interface';
import { ChatWsMessageType } from './chatWsWrapper';
import {
  ChatProxyTargetCreated,
  ChatProxyWsEvent,
  ChatProxyWsIncomingMessage,
  ChatProxyWsOutgoingMessage,
} from './chatProxyWsMessage';
import { ChatAttachment, ChatMessagePayloadType, ChatMessageStatus } from '../../types/chatMessage';
import dateTimeService from '../../service/dateTimeService';
import phoneNumberService from '../../service/phoneNumberService';
import { ChatClientType } from '../../types/chatClient';

export abstract class AbstractChatProxyListener implements ChatListener {
  private registeredListeners: string[] = [];

  protected constructor(private readonly channel: SupportedChatChannel,
                        private readonly config: ChatConfig) {}

  register(callbacks: ChatListenerCallbacks): void {
    this.registeredListeners = [
      chatProxyWsConnection.addListener({
        channel: this.channel,
        msgType: ChatWsMessageType.EVENT,
        onWsMessage: wsMessage => this.onChatEvent(wsMessage, callbacks),
      }),
      chatProxyWsConnection.addListener({
        channel: this.channel,
        msgType: ChatWsMessageType.INCOMING_MESSAGE,
        onWsMessage: wsMessage => this.onChatIncomingMessage(wsMessage, callbacks),
      }),
      chatProxyWsConnection.addListener({
        channel: this.channel,
        msgType: ChatWsMessageType.OUTGOING_MESSAGE,
        onWsMessage: wsMessage => this.onChatOutgoingMessage(wsMessage, callbacks),
      }),
      chatProxyWsConnection.addListener({
        channel: this.channel,
        msgType: ChatWsMessageType.TARGET_CREATED,
        onWsMessage: wsMessage => this.onChatTargetCreated(wsMessage, callbacks),
      }),
    ];
  }

  unregister(): void {
    this.registeredListeners.forEach(listenerId => {
      chatProxyWsConnection.removeListener(listenerId);
    });
  }

  private onChatEvent(wsMessage: ChatProxyWsEvent, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    callbacks.onEvent?.({
      channel: this.channel,
      msgId: payload.msgId,
      status: payload.status,
      statusDetails: payload.details,
      timestamp: payload.timestamp,
    });
  }

  private onChatIncomingMessage(wsMessage: ChatProxyWsIncomingMessage, callbacks: ChatListenerCallbacks): void {
    const { payload: msg } = wsMessage;

    const attachments: ChatAttachment[] = (msg.payload.attachments || [])
      .map(att => ({
        link: att.mediaUrl,
        filename: att.filename,
      }));

    callbacks.onIncomingMessage?.({
      channel: this.channel,
      senderId: msg.accountId,
      clientId: msg.targetId,
      msgId: msg.uuid,
      message: msg.payload.text,
      date: dateTimeService.formatCurrentTimestamp(this.config.dateFormat),
      status: ChatMessageStatus.UNREAD,
      outgoing: false,
      payloadType: msg.payload.type as ChatMessagePayloadType,
      attachments,
      button: msg.payload.button,
      statusCode: 200,
      error: null,
    });
  }

  private onChatOutgoingMessage(wsMessage: ChatProxyWsOutgoingMessage, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    const attachments: ChatAttachment[] = [];
    if (payload.attachmentLink) {
      attachments.push({
        link: payload.attachmentLink,
        filename: payload.fileName,
      });
    }

    callbacks.onOutgoingMessage?.({
      senderId: payload.accountId,
      clientId: payload.targetId,
      msgId: payload.messageId,
      message: payload.message,
      date: dateTimeService.formatCurrentTimestamp(this.config.dateFormat),
      outgoing: true,
      channel: this.channel,
      status: ChatMessageStatus.QUEUED,
      payloadType: payload.payloadType as ChatMessagePayloadType,
      attachments,
      button: payload.button,
      statusCode: 200,
      error: null,
    });
  }

  private onChatTargetCreated(wsMessage: ChatProxyTargetCreated, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    callbacks.onClientCreated?.({
      channel: this.channel,
      id: payload.targetId,
      type: ChatClientType.NUMERIC,
      number: phoneNumberService.toPhoneNumberWithPlusSign(payload.destination),
      lastActivityTime: payload.timestamp,
      senderId: payload.accountId,
      foundInContacts: false,
    });
  }
}
